<template>
    <div class="row">
        <div class="col-12">

            <div class="card">

                <div class="card-body">

                    <div class="alert alert-custom alert-indicator-left indicator-info" role="alert">
                        <div class="alert-content">
                            <span class="alert-title">Important!</span>
                            <span class="alert-text">This facility will only search databases of companies which have explicitly agreed to let us provide their data in this way. An empty result does not necessarily mean we have not seen the vehicle but we may not be able to tell you where we have seen it.</span>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="search">Search</label>
                        <input type="text" v-model="search" class="form-control" id="search" placeholder="Enter registration number">
                    </div>

                    <button class="btn btn-sm btn-primary m-t-sm float-end" @click="initiateSearch">Search</button>

                </div>

            </div>

            <div class="card m-t-sm">

                <div class="card-body">

                    <p v-if="searching">Status: {{ searchStatus }}</p>

                    <p v-if="!searching">
                        <span style="display: inline-block;">Found {{ searchResults.length }} results</span>
                        <button v-if="searchResults.length > 0" style="display: inline-block;" class="btn btn-sm btn-primary m-t-sm float-end" @click="showMap = !showMap">{{ showMap ? 'Hide Map' : 'Show on Map' }}</button>
                    </p>

                    <div v-if="showMap">

                        <div class="form-group">
                            <label for="start_date">Start Date</label>
                            <input type="date" v-model="startDate" class="form-control" id="start_date">
                        </div>

                        <div class="form-group">
                            <label for="end_date">End Date</label>
                            <input type="date" v-model="endDate" class="form-control" id="end_date">
                        </div>

                        <button class="btn btn-sm btn-primary m-t-sm float-end" @click="initMap">Populate Map</button>

                        <Map v-if="mapReady" :events="searchResults" :start_date="startDateSeconds" :end_date="endDateSeconds"></Map>

                    </div>

                    <div v-else>
                        <Pagination :config="paginationConfig" @search="updatePage"></Pagination>

                        <table class="table">
                            <thead class="bg-primary">
                                <tr>
                                    <th scope="col"></th>
                                    <th scope="col">Registration</th>
                                    <th scope="col">Location</th>
                                    <th scope="col">Company</th>
                                    <th scope="col">Date/Time Seen</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="result in paginatedResults" :key="result.id + '-' + result.site.id">
                                    <td>
                                        <EventImageVue :filename="result.image" style="width: 150px; height: auto;" :site_id="result.site.id" :thumb="false" :storage_account="result.storage_account"></EventImageVue>
                                    </td>
                                    <td>{{ result.vehicle.plate }}</td>
                                    <td>{{ result.site.name }}<br/>{{ result.site.address_one }}<br/>{{ result.site.address_two }}<br/>{{ result.site.address_three }}<br/>{{ result.site.address_four }}<br/>{{ result.site.postcode }}</td>
                                    <td>{{ result.client.name }}</td>
                                    <td>{{ result.created_at }}</td>
                                </tr>
                                <tr v-if="searchResults.length < 1 && !searching">
                                    <td colspan="5">No results found...</td>
                                </tr>
                                <tr v-if="searching">
                                    <td colspan="5">
                                        <div class="d-flex justify-content-center">
                                            <div class="spinner-border text-primary" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>
                
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import authHeader from '../../services/auth-header';
import Pagination from '../../components/Pagination.vue';
import { DateTime } from 'luxon';
import EventImageVue from '../../components/EventImage.vue';
import Map from './Map.vue';

export default {
    name: 'Search',
    components: {
        Pagination,
        EventImageVue,
        Map
    },
    data(){
        return {
            search: '',
            searching: false,
            searchStatus: '',
            searchResults: [],
            statusUrl: null,
            pagination: {
                currentPage: 1,
                perPage: 25,
                total: 0
            },
            showMap: false,
            mapReady: false,
            startDate: null,
            endDate: null,
            startDateSeconds: null,
            endDateSeconds: null
        }
    },
    mounted(){

    },
    methods: {
        updatePage(page){
            this.pagination.currentPage = page;
        },
        initiateSearch(){

            this.showMap = false;
            this.mapReady = false;
            this.startDate = null;
            this.endDate = null;
            this.startDateSeconds = null;
            this.endDateSeconds = null;

            this.searchStatus = 'Starting...';
            this.searchResults = [];
            this.statusUrl = null;
            this.searching = true;
            axios.post(`https://api.varsanpr.com/api/nbcs/search`, {
                registration: this.search
            }, {
                headers: authHeader(),
                validateStatus: function (status) {
                    return status >= 200 && status < 300;
                },
            })
            .then(response => {
                this.searchStatus = 'Searching...';
                this.statusUrl = response.data.statusQueryGetUri;
                this.checkSearchStatus();
            })
            .catch(error => {
                this.$error('Failed to initiate search', error);
                this.searchStatus = 'Failed';
            });

        },
        checkSearchStatus(){
            
            axios.get(this.statusUrl)
            .then(response => {
                this.searchStatus = response.data.runtimeStatus;
                if(response.data.runtimeStatus == 'Completed'){
                    this.searchResults = response.data.output;
                    this.searchResults.forEach((res) => {
                        res.created_at_seconds = res.created_at;
                        res.created_at = DateTime.fromSeconds(res.created_at).toFormat('dd/MM/yyyy HH:mm:ss');
                    })
                    this.pagination.total = response.data.output.length;
                    this.pagination.currentPage = 1;
                    this.searching = false;
                } else {
                    setTimeout(this.checkSearchStatus, 5000);
                }
            })
            .catch(error => {
                this.$error('Failed to check search status', error);
                this.searchStatus = 'Failed';
            });

        },
        initMap(){
            this.mapReady = false;
            this.startDateSeconds = DateTime.fromFormat(this.startDate, 'yyyy-MM-dd').toSeconds();
            this.endDateSeconds = DateTime.fromFormat(this.endDate, 'yyyy-MM-dd').toSeconds();
            setTimeout(() => {
                this.mapReady = true;
            }, 1000);
        }
    },
    computed: {
        paginatedResults(){
            return this.searchResults.slice((this.pagination.currentPage - 1) * this.pagination.perPage, this.pagination.currentPage * this.pagination.perPage);
        },
        paginationConfig(){
            return {
                page: this.pagination.currentPage, 
                maxPage: Math.ceil(this.pagination.total / this.pagination.perPage), 
                showing: this.pagination.perPage,
                total: this.pagination.total
            }
        }
    }
}
</script>